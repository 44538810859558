/* AllianceNo1 */
@font-face {
  font-family: "Alliance";
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url("/fonts/AllianceNo1Light/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1Light/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 300;
  font-style: italic;
  font-display: block;
  src: url("/fonts/AllianceNo1LightItalic/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1LightItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url("/fonts/AllianceNo1Regular/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1Regular/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: 400;
  font-style: italic;
  font-display: block;
  src: url("/fonts/AllianceNo1RegularItalic/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1RegularItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bold;
  font-style: normal;
  font-display: block;
  src: url("/fonts/AllianceNo1SemiBold/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1SemiBold/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bold;
  font-style: italic;
  font-display: block;
  src: url("/fonts/AllianceNo1SemiBoldItalic/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1SemiBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bolder;
  font-style: normal;
  font-display: block;
  src: url("/fonts/AllianceNo1Medium/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1Medium/font.woff") format("woff");
}
@font-face {
  font-family: "Alliance";
  font-weight: bolder;
  font-style: italic;
  font-display: block;
  src: url("/fonts/AllianceNo1MediumItalic/font.woff2") format("woff2"),
    url("/fonts/AllianceNo1MediumItalic/font.woff") format("woff");
}

/* Hide the recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

.dynamic-no-scroll--ios {
  /* dynamic sets a position: fixed which causes layout issues on mobile, so unsetting it here */
  position: unset !important;
}
